<template>
  <b-tabs content-class="mt-3">
    <seo-tab
        ref="seoTab"
        :entity-id="entityId"
        entity-type="blogTag"
    />
    <log-tab
        :entity-id="entityId"
        :entity-type="entityType"
    />
  </b-tabs>
</template>

<script>
import {
  BTabs,
} from 'bootstrap-vue'
import seoTab from '@/views/pages/_components/_tab/_shared/_update/seo.vue'
import logTab from '@/views/pages/_components/_tab/_log/_list.vue'

export default {
  components: {
    BTabs,
    seoTab,
    logTab,
  },
  props: {
    entityId: {
      type: Number,
      required: true
    },
    entityType: {
      type: String,
      required: true
    }
  },
  methods: {
    refreshTabData() {
      this.$emit('refresh-tabs-data')
    }
  }
}
</script>

<style scoped>

</style>
