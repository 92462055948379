<template>
  <b-tab active>
    <template #title>
      <feather-icon
          icon="SearchIcon"
          size="21"
      />
      <strong>
        {{ $t('tab.title.seo') }}
      </strong>
    </template>

    <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        rounded="sm"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.metaRobotsIndex').toLowerCase()"
                    rules=""
                    vid="metaRobotsIndex"
                >
                  <b-form-checkbox v-model="seo.metaRobotsIndex"
                                   :state="errors.length > 0 ? false:null"
                  >
                    {{ $t('form.metaRobotsIndex') }}
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.metaRobotsFollow').toLowerCase()"
                    rules=""
                    vid="metaRobotsFollow"
                >
                  <b-form-checkbox v-model="seo.metaRobotsFollow"
                                   :state="errors.length > 0 ? false:null"
                  >
                    {{ $t('form.metaRobotsFollow') }}
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                  :label="$t('form.metaTitle')"
                  :description="$t('form.helpDescription.metaTitle')"
                  class="mb-0"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.metaTitle').toLowerCase()"
                    rules="required|min:3|max:191"
                    vid="metaTitle"
                >
                  <b-form-input
                      v-model="seo.metaTitle"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('form.metaTitle').toLowerCase()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-progress class="mt-1 mb-2" :max="65" show-value>
                <b-progress-bar
                    :value="seo.metaTitle.length"
                    :variant="progress.metaTitle"
                />
              </b-progress>
            </b-col>

            <b-col md="12">
              <b-form-group
                  :label="$t('form.metaDescription')"
                  :description="$t('form.helpDescription.metaDescription')"
                  class="mb-0"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.metaDescription').toLowerCase()"
                    rules="required|min:3"
                    vid="metaDescription"
                >
                  <b-form-textarea
                      v-model="seo.metaDescription"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('form.metaDescription').toLowerCase()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-progress class="mt-1 mb-2" :max="156" show-value>
                <b-progress-bar
                    :value="seo.metaDescription.length"
                    :variant="progress.metaDescription"
                />
              </b-progress>
            </b-col>

            <b-col md="12">
              <b-form-group
                  :label="$t('form.ogTitle')"
                  :description="$t('form.helpDescription.ogTitle')"
                  class="mb-0"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.ogTitle').toLowerCase()"
                    rules="required|min:3|max:191"
                    vid="ogTitle"
                >
                  <b-form-input
                      v-model="seo.ogTitle"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('form.ogTitle').toLowerCase()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-progress class="mt-1 mb-2" :max="65" show-value>
                <b-progress-bar
                    :value="seo.ogTitle.length"
                    :variant="progress.ogTitle"
                />
              </b-progress>
            </b-col>

            <b-col md="12">
              <b-form-group
                  :label="$t('form.ogDescription')"
                  :description="$t('form.helpDescription.ogDescription')"
                  class="mb-0"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.ogDescription').toLowerCase()"
                    rules="required|min:3"
                    vid="ogDescription"
                >
                  <b-form-textarea
                      v-model="seo.ogDescription"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('form.ogDescription').toLowerCase()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-progress class="mt-1 mb-2" :max="156" show-value>
                <b-progress-bar
                    :value="seo.ogDescription.length"
                    :variant="progress.ogDescription"
                />
              </b-progress>
            </b-col>

            <b-col md="12" class="mb-3">
              <b-row>
                <b-col sm="11">
                  <b-form-group
                      :label="$t('form.ogImage')"
                      :description="$t('form.helpDescription.ogImage')"
                      class="mb-0"
                  >
                    <b-form-file
                        v-model="seo.ogImage"
                        placeholder="Válassz vagy húzd ide a fájlt"
                        drop-placeholder="A fájlt húzd ide..."
                        :browse-text="$t('label.browse')"
                        accept="
                                        image/bmp,
                                        image/gif,
                                        image/jpeg,
                                        image/png,
                                        image/webp
                                      "
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="1">
                  <b-button
                      v-if="seo.ogImage && !Array.isArray(seo.ogImage)"
                      variant="danger"
                      class="float-right"
                      @click.prevent="resetOgImage"
                  >
                    <feather-icon
                        icon="Trash2Icon"
                        size="15"
                    />
                  </b-button>
                </b-col>
                <b-col sm="12" class="mt-2">
                  <img style="max-width: 200px" :src="seo.ogImageUrl" alt="OG image"
                       class="img-thumbnail">
                </b-col>
              </b-row>
            </b-col>

            <b-col md="12">
              <b-form-group
                  :label="$t('form.twitterTitle')"
                  :description="$t('form.helpDescription.twitterTitle')"
                  class="mb-0"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.twitterTitle').toLowerCase()"
                    rules="required|min:3|max:191"
                    vid="twitterTitle"
                >
                  <b-form-input
                      v-model="seo.twitterTitle"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('form.twitterTitle').toLowerCase()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-progress class="mt-1 mb-2" :max="65" show-value>
                <b-progress-bar
                    :value="seo.twitterTitle.length"
                    :variant="progress.twitterTitle"
                />
              </b-progress>
            </b-col>

            <b-col md="12">
              <b-form-group
                  :label="$t('form.twitterDescription')"
                  :description="$t('form.helpDescription.twitterDescription')"
                  class="mb-0"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.twitterDescription').toLowerCase()"
                    rules="required|min:3"
                    vid="twitterDescription"
                >
                  <b-form-textarea
                      v-model="seo.twitterDescription"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('form.twitterDescription').toLowerCase()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-progress class="mt-1 mb-2" :max="156" show-value>
                <b-progress-bar
                    :value="seo.twitterDescription.length"
                    :variant="progress.twitterDescription"
                />
              </b-progress>
            </b-col>

            <b-col md="12" class="mb-3">
              <b-row>
                <b-col sm="11">
                  <b-form-group
                      :label="$t('form.twitterImage')"
                      :description="$t('form.helpDescription.twitterImage')"
                      class="mb-0"
                  >
                    <b-form-file
                        v-model="seo.twitterImage"
                        placeholder="Válassz vagy húzd ide a fájlt"
                        drop-placeholder="A fájlt húzd ide..."
                        :browse-text="$t('label.browse')"
                        accept="
                                            image/bmp,
                                            image/gif,
                                            image/jpeg,
                                            image/png,
                                            image/webp
                                          "
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="1">
                  <b-button
                      v-if="seo.twitterImage && !Array.isArray(seo.twitterImage)"
                      variant="danger"
                      class="float-right"
                      @click.prevent="resetTwitterImage"
                  >
                    <feather-icon
                        icon="Trash2Icon"
                        size="15"
                    />
                  </b-button>
                </b-col>
                <b-col sm="12" class="mt-2">
                  <img style="max-width: 200px" :src="seo.twitterImageUrl" alt="Twitter image"
                       class="img-thumbnail">
                </b-col>
              </b-row>
            </b-col>

            <!-- submit button -->
            <b-col md="12" class="mt-1">
              <b-button
                  variant="primary"
                  type="submit"
                  class="float-right"
                  @click.prevent="submitForm"
              >
                <b-overlay
                    :show="loading"
                    :opacity="0.1"
                    spinner-variant="info"
                    spinner-type="grow"
                    spinner-small
                    rounded="sm"
                >
                  {{ $t('label.button.save') }}
                </b-overlay>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>
  </b-tab>
</template>

<script>
import {
  BTabs,
  BTab,
  BOverlay,
  BCard,
  BCardHeader,
  BCardBody,
  BCardText,
  BButton,
  BCollapse,
  BFormGroup,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BProgress,
  BProgressBar,
  BFormTextarea,
  BFormCheckbox,
  VBToggle, BFormFile,
} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider} from "vee-validate";

export default {
  components: {
    BFormFile,
    BFormInput,
    BFormTextarea,
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BTab,
    BOverlay,
    BCard,
    BCardHeader,
    BCardBody,
    BCardText,
    BButton,
    BCollapse,
    BProgress,
    BProgressBar,
    BFormCheckbox,
    BTabs,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    'b-toggle': VBToggle
  },
  props: {
    entityId: {
      type: Number,
      required: true
    },
    entityType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      seo: {
        modelType: '',
        modelId: '',
        metaRobotsIndex: false,
        metaRobotsFollow: false,
        metaTitle: '',
        metaDescription: '',
        ogTitle: '',
        ogDescription: '',
        ogImage: [],
        ogImageUrl: '',
        twitterTitle: '',
        twitterDescription: '',
        twitterImage: [],
        twitterImageUrl: '',
      },
      progress: {
        metaTitle: 'success',
        metaDescription: 'success',
        ogTitle: 'success',
        ogDescription: 'success',
        twitterTitle: 'success',
        twitterDescription: 'success',
      },
    }
  },
  created() {
    this.initTab()
  },
  watch: {
    seo: {
      handler() {
        this.checkMetaTitleLength()
        this.checkMetaDescriptionLength()
      },
      deep: true
    }
  },
  methods: {
    initTab() {
      this.$store.dispatch('getSeoForEntity', {
        id: this.$route.params.id,
        type: this.entityType
      }).then(async response => {
        let seo = response.data.seo
        this.seo.modelType = this.entityType
        this.seo.modelId = this.$route.params.id
        this.seo.metaRobotsIndex = seo.meta_robots_index
        this.seo.metaRobotsFollow = seo.meta_robots_follow

        this.seo.metaTitle = seo.meta_title
        this.seo.metaDescription = seo.meta_description ?? ''
        this.seo.ogTitle = seo.og_title
        this.seo.ogDescription = seo.og_description ?? ''
        this.seo.ogImageUrl = seo.og_image_url
        this.seo.twitterTitle = seo.twitter_title
        this.seo.twitterDescription = seo.twitter_description ?? ''
        this.seo.twitterImageUrl = seo.twitter_image_url

      }).catch(error => {
        if (error.response.status === 422) {
          this.$refs.simpleRules.setErrors(error.response.data.errors)
        } else {
          this.$helpers.showErrorToast(error.toString())
        }
      })
    },
    checkMetaTitleLength() {
      if (this.seo.metaTitle.length > 65) {
        this.progress.metaTitle = 'warning'
      } else {
        this.progress.metaTitle = 'success'
      }

      if (this.seo.ogTitle.length > 65) {
        this.progress.ogTitle = 'warning'
      } else {
        this.progress.ogTitle = 'success'
      }

      if (this.seo.twitterTitle.length > 65) {
        this.progress.twitterTitle = 'warning'
      } else {
        this.progress.twitterTitle = 'success'
      }
    },
    checkMetaDescriptionLength() {
      if (this.seo.metaDescription.length > 200) {
        this.progress.metaDescription = 'danger'
      } else if (this.seo.metaDescription.length > 156) {
        this.progress.metaDescription = 'warning'
      } else {
        this.progress.metaDescription = 'success'
      }

      if (this.seo.ogDescription.length > 200) {
        this.progress.ogDescription = 'danger'
      } else if (this.seo.ogDescription.length > 156) {
        this.progress.ogDescription = 'warning'
      } else {
        this.progress.ogDescription = 'success'
      }

      if (this.seo.twitterDescription.length > 200) {
        this.progress.twitterDescription = 'danger'
      } else if (this.seo.twitterDescription.length > 156) {
        this.progress.twitterDescription = 'warning'
      } else {
        this.progress.twitterDescription = 'success'
      }
    },
    resetOgImage() {
      this.seo.ogImage = []
    },
    resetTwitterImage() {
      this.seo.twitterImage = []
    },
    showLoadBar() {
      this.loading = true
    },
    hideLoadBar() {
      this.loading = false
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const fd = new FormData();
          fd.append('modelType', this.entityType)
          fd.append('modelId', this.entityId.toString())
          fd.append('metaTitle', this.seo.metaTitle)
          fd.append('metaDescription', this.seo.metaDescription)
          fd.append('ogTitle', this.seo.ogTitle)
          fd.append('ogDescription', this.seo.ogDescription)
          fd.append('ogImage', this.seo.ogImage)
          fd.append('twitterTitle', this.seo.twitterTitle)
          fd.append('twitterDescription', this.seo.twitterDescription)
          fd.append('twitterImage', this.seo.twitterImage)
          fd.append('metaRobotsIndex', this.seo.metaRobotsIndex)
          fd.append('metaRobotsFollow', this.seo.metaRobotsFollow)

          this.$store.dispatch('updateSeoForEntity', fd).then(() => {
            this.$helpers.showSuccessToast()
            this.initTab()
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

.accordion .card {
  margin-bottom: 0 !important;
}

.accordion .card .card-header {
  padding: 0 !important;
}
</style>
